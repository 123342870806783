<template>
  <div>
    <b-row>
      <b-col>
        <h3>Comentários</h3>
        <span>Deixe seu comentário/solicitação para a Divy</span>
      </b-col>
    </b-row>
    <b-row
      class="pt-1"
    >
      <b-col>
        <b-form-textarea
          id="textarea"
          v-model="text"
          placeholder="Deixe seu comentário"
          rows="3"
          max-rows="6"
        />
      </b-col>
    </b-row>
    <div
      v-for="coment in coments"
      :key="coment.text"
    >
        <b-row
        class="pt-1"
        >
        <b-col>
            <b-card border-variant="dark">
            <b-card-text>
                <b-row align-h="between">
                <b-col cols="4">
                    <span><strong>{{ coment.user }}</strong> comentou em {{ coment.data }}</span>
                </b-col>
                <b-col cols="1">
                    <edit-2-icon size="1.5x" />
                    <trash-2-icon size="1.5x" />
                </b-col>
                </b-row>
            </b-card-text>
            <b-row
                class="pt-1"
            >
                <b-col>
                <span>{{ coment.text }}</span>
                </b-col>
            </b-row>
            </b-card>
        </b-col>
        </b-row>
    </div>
  </div>
</template>

<script>

import { Edit2Icon, Trash2Icon } from 'vue-feather-icons'
import {
  BRow,
  BCol,
  BFormTextarea,
  BCard,
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormTextarea,
    Edit2Icon,
    Trash2Icon,
    BCard,
    BCardText,
  },
  data() {
    return {
      coments: [
        { user: 'Divy', text: 'Email da construtora alterado conforme solicitado', data: '25/10/2021' },
        { user: 'construtora', text: 'Favor alterar o email da construtora para contato', data: '23/10/2021' },
      ],
    }
  },
}
</script>

<style>

</style>
