<template>
  <b-tabs justified pills>
    <b-tab title="Informações Essenciais" active>
      <text-editor />
    </b-tab>
    <b-tab title="Construtora">
      <b-card-text> Descrição da Construtora  </b-card-text>
    </b-tab>
    <b-tab title="Fotos 8">
      <b-row>
        <b-col v-for="item in items" :key="item.id" sm="2" class="pb-1">
          <b-img class="imgt" thumbnail fluid :src="item" alt="image 1" />
        </b-col>
        <b-col sm="2">
          <div style="height: 150px; width: 130px" class="d-flex flex-column bg-secondary mr-1 align-items-center justify-content-center">
            <feather-icon size="2x" class="text-light" icon="UploadIcon" />
            <span class="text-light text-center">Adicionar Fotos JPEG ou PNG somente</span>
          </div>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab title="Planta Baixa">
      <b-row>
        <span>Planta Baixa</span>
      </b-row>
      <b-row>
        <b-col v-for="item in items" :key="item.id" sm="2" class="pb-1">
          <b-img class="imgt" thumbnail fluid :src="item" alt="image 1" />
        </b-col>
        <b-col sm="2">
          <div style="height: 150px; width: 130px" class="d-flex flex-column bg-secondary mr-1 align-items-center justify-content-center">
            <feather-icon size="2x" class="text-light" icon="UploadIcon" />
            <span class="text-light text-center">Adicionar Fotos JPEG ou PNG somente</span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="" label-for="search">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="PaperclipIcon" />
              </b-input-group-prepend>
              <b-form-file v-model="file" placeholder="Choose a file or drop it here..." />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab title="Documentos">
      <b-row>
        <b-col>
          <b-tabs justified pills>
            <b-tab title="Documentos Comerciais">
              <b-row>
                <b-col>
                  <b-form-group label="Nome do documento">
                    <b-form-file />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="2">
                  <div style="height: 150px; width: 150px" class="d-flex flex-column bg-secondary mr-1 align-items-center justify-content-center justify-content-between">
                    <span class="text-light text-center mt-1">Folder</span>
                    <feather-icon size="2x" class="text-light" icon="UploadIcon" />
                    <!-- <b-form-select id="status-imovel" v-model="maquinas" :options="status"></b-form-select> -->
                    <select id="" name="" style="width: 100%">
                      <option value="xx">Confirmado</option>
                      <option value="yy">Rejeitado</option>
                    </select>
                  </div>
                </b-col>
                <b-col sm="2">
                  <div style="height: 150px; width: 150px" class="d-flex flex-column bg-secondary mr-1 align-items-center justify-content-center justify-content-between">
                    <span class="text-light text-center mt-1">Apresentação</span>
                    <feather-icon size="2x" class="text-light" icon="UploadIcon" />
                    <!-- <b-form-select id="status-imovel" v-model="maquinas" :options="status"></b-form-select> -->
                    <select id="" name="" style="width: 100%">
                      <option value="xx">Confirmado</option>
                      <option value="yy">Rejeitado</option>
                    </select>
                  </div>
                </b-col>
                <b-col sm="2">
                  <div style="height: 150px; width: 150px" class="d-flex flex-column bg-secondary mr-1 align-items-center justify-content-center justify-content-between">
                    <span class="text-light text-center mt-1">Cronograma</span>
                    <feather-icon size="2x" class="text-light" icon="UploadIcon" />
                    <!-- <b-form-select id="status-imovel" v-model="maquinas" :options="status"></b-form-select> -->
                    <select id="" name="" style="width: 100%">
                      <option value="xx">Confirmado</option>
                      <option value="yy">Rejeitado</option>
                    </select>
                  </div>
                </b-col>
                <b-col sm="2">
                  <div style="height: 150px; width: 150px" class="d-flex flex-column bg-secondary mr-1 align-items-center justify-content-center justify-content-between">
                    <span class="text-light text-center mt-1">Planejamento</span>
                    <feather-icon size="2x" class="text-light" icon="UploadIcon" />
                    <!-- <b-form-select id="status-imovel" v-model="maquinas" :options="status"></b-form-select> -->
                    <select id="" name="" style="width: 100%">
                      <option value="xx">Confirmado</option>
                      <option value="yy">Rejeitado</option>
                    </select>
                  </div>
                </b-col>
              </b-row>
              <b-row class="pt-1 justify-content-end">
                <b-col sm="1" class="mr-3">
                  <b-button variant="primary">Aprovar</b-button>
                </b-col>
                <b-col sm="1" class="mr-3">
                  <b-button variant="primary">Rejeitar</b-button>
                </b-col>
                <b-col sm="1" class="mr-3">
                  <b-button variant="primary">Salvar</b-button>
                </b-col>
                <b-col sm="1" class="mr-3">
                  <b-button variant="primary">Voltar</b-button>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Documentos Contratuais 1">
              <b-row>
                <b-col>
                  <h3>Documentos Contratuais</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span>Os documentos e contratos serão formulados e postados no ato da deliberação do imóvel</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <app-collapse>
                    <app-collapse-item title="Nome do Documento 1">
                      <!-- <b-row>
                        <b-col>
                          <b-form-group label="Signatarios" label-for="sig-imovel">
                            <b-form-input id="sig-imovel" placeholder="3" />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group label="Email" label-for="email-imovel">
                            <b-form-input id="email-imovel" placeholder="3" />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group label="Status" label-for="status-imovel">
                            <b-form-input id="status-imovel" placeholder="3" />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-input />
                        </b-col>
                        <b-col>
                          <b-form-input />
                        </b-col>
                        <b-col>
                          <b-form-input />
                        </b-col>
                      </b-row>
                      <b-row
                        class="mt-1"
                      >
                        <b-col>
                          <b-form-input />
                        </b-col>
                        <b-col>
                          <b-form-input />
                        </b-col>
                        <b-col>
                          <b-form-input />
                        </b-col>
                      </b-row>
                      <b-row
                        class="mt-1"
                      >
                        <b-col>
                          <b-button>Adicionar Signatario</b-button>
                        </b-col>
                      </b-row> -->
                      <form-repeater />
                    </app-collapse-item>

                    <app-collapse-item title="Nome do Documento 2">
                      <form-repeater />
                    </app-collapse-item>

                    <app-collapse-item title="Nome do Documento 3">
                      Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate
                      cake lemon drops candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly beans pie candy canes candy canes jelly-o.
                      Tiramisu brownie gummi bears soufflé dessert cake.
                    </app-collapse-item>

                    <app-collapse-item title="Nome do Documento 4">
                      Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate
                      cake lemon drops candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly beans pie candy canes candy canes jelly-o.
                      Tiramisu brownie gummi bears soufflé dessert cake.
                    </app-collapse-item>
                  </app-collapse>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BTabs,
  BTab,
  BFormFile,
  BImg,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  // BFormSelect,
  BButton,
  BCardText,
  // BFormInput,
} from 'bootstrap-vue'
import TextEditor from './TextEditor.vue'
import FormRepeater from './FormRepeaterDocumentosContratuais.vue'

export default {
  components: {
    BTabs,
    BTab,
    TextEditor,
    BFormFile,
    BImg,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    // BFormSelect,
    BButton,
    BCardText,
    AppCollapse,
    AppCollapseItem,
    // BFormInput,
    FormRepeater,
  },
  data() {
    return {
      items: [
        'https://picsum.photos/250/250/?image=54',
        'https://picsum.photos/250/250/?image=58',
        'https://picsum.photos/250/250/?image=59',
        'https://picsum.photos/250/250/?image=60',
        'https://picsum.photos/250/250/?image=61',
        'https://picsum.photos/250/250/?image=54',
        'https://picsum.photos/250/250/?image=58',
        'https://picsum.photos/250/250/?image=59',
        'https://picsum.photos/250/250/?image=60',
        'https://picsum.photos/250/250/?image=61',
      ],
      status: [{ value: 'xx', text: 'text' }],
      text: 'teste teste',
    }
  },
}
</script>

<style>
.inpf {
  height: 100px;
  width: 100px;
}
.imgt {
  height: 150px;
  width: 150px;
}
</style>
