<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <h3>Dados do Imóvel teste</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Projeto/Obra" label-for="projeto-imovel">
            <b-form-select id="projeto-imovel" v-model="projetoObra" :options="projetos" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <b-form-group label="Nome do Imóvel" label-for="nome-imovel">
            <b-form-input id="nome-imovel" v-model="form.nomeImovel" placeholder="Nome do Imóvel" />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="CEP" label-for="cep-imovel">
            <cleave id="cep-imovel" v-model="form.cep" type class="form-control" :raw="false" :options="cleaveOptions.cep" placeholder="00.000-000" @blur="buscaCep()" />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group label="Rua" label-for="rua-imovel">
            <b-form-input id="rua-imovel" v-model="form.logradouro" placeholder="Rua x" readonly />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Número" label-for="numero-imovel">
            <b-form-input id="numero-imovel" v-model="form.numero" type="number" placeholder="999" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <b-form-group label="Complemento" label-for="comp-imovel">
            <b-form-input id="comp-imovel" v-model="form.complemento" placeholder="Complemento do endereço" />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Bairro" label-for="bairro-imovel">
            <b-form-input id="bairro-imovel" v-model="form.bairro" placeholder="Bairro do imóvel" readonly />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group label="Cidade" label-for="cidade-imovel">
            <b-form-input id="cidade-imovel" v-model="form.cidade" placeholder="Nome da Cidade" readonly />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Estado" label-for="estado-imovel">
            <b-form-input id="estado-imovel" v-model="form.estado" placeholder="SP" readonly />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="2">
          <b-form-group label="Número da Unidade" label-for="unidade-imovel">
            <b-form-input id="unidade-imovel" v-model="form.numeroUnidade" placeholder="999" />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Area Privada m²" label-for="area-imovel">
            <b-form-input id="area-imovel" v-model="form.area" type="number" placeholder="90" />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Tipo do Imóvel" label-for="tipo-imovel">
            <b-form-input id="tipo-imovel" v-model="form.tipo" placeholder="Apartamento,Loft,Cobertura,etc" />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Qtd Suítes" label-for="suites-imovel">
            <b-form-input id="suites-imovel" v-model="form.suites" type="number" placeholder="2" />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Qtd Dormitórios" label-for="dormitorios-imovel">
            <b-form-input id="dormitorios-imovel" v-model="form.dormitorios" type="number" placeholder="3" />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Qtd Banheiros" label-for="banheiros-imovel">
            <b-form-input id="banheiros-imovel" v-model="form.banheiros" type="number" placeholder="2" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="2">
          <b-form-group label="Qtd Lavabo" label-for="lavabo-imovel">
            <b-form-input id="lavabo-imovel" v-model="form.lavabo" type="number" placeholder="1" />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="N° Andares" label-for="andares-imovel">
            <b-form-input id="andares-imovel" v-model="form.andares" type="number" placeholder="3" />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Vagas de Garagem" label-for="garagem-imovel">
            <b-form-input id="garagem-imovel" v-model="form.garagem" type="number" placeholder="3" />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Área de Serviço" label-for="servico-imovel">
            <b-form-select id="servico-imovel" v-model="form.service" :options="options" />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Closet" label-for="closet-imovel">
            <b-form-select id="closet-imovel" v-model="form.closet" :options="options" />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="Piscina Privativa" label-for="piscina-imovel">
            <b-form-select id="piscina-imovel" v-model="form.piscina" :options="options" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <b-form-group label="Quarto de Empregada" label-for="empregada-imovel">
            <b-form-select id="empregada-imovel" v-model="form.quartoEmp" :options="options" />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group label="churrasqueira" label-for="churrasqueira-imovel">
            <b-form-select id="churrasqueira-imovel" v-model="form.churrasqueira" :options="options" />
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group label="Área de Máquinas" label-for="maquinas-imovel">
            <b-form-select id="maquinas-imovel" v-model="form.maquinas" :options="options" />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group label="Remuneração Esperada" label-for="remuneracao-imovel">
            <b-input-group class="input-group-merge">
              <cleave id="remuneracao-imovel" v-model="form.remuneracao" type class="form-control" :raw="false" :options="cleaveOptions.money" placeholder="10.000.000,00" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <b-form-group label="Valor da Cota" label-for="cota-imovel">
            <cleave id="cota-imovel" v-model="form.cota" type class="form-control" :raw="false" :options="cleaveOptions.money" placeholder="4.000,00" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Prazo máximo de venda de 50% das cotas" label-for="prazo-imovel">
            <flat-pickr v-model="prazo" class="form-control" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col>
          <h4>Deliberação do Imóvel</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <b-form-group label="Status" label-for="status-imovel">
            <b-form-select id="status-imovel" v-model="form.status" :options="status" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span>Parecer</span>
          <text-editor />
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col>
          <form-wizard-dados-imovel />
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <comentarios />
    </b-card>
  </div>
</template>

<script>
// import VueMask from 'v-mask'
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BCard,
  BFormSelect,
  BInputGroup,
  // BInputGroupPrepend,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'
import Comentarios from './Comentarios.vue'
import FormWizardDadosImovel from './FormDadosImovel.vue'
import TextEditor from './TextEditor.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BCard,
    BFormSelect,
    // BInputGroupPrepend,
    BInputGroup,
    FormWizardDadosImovel,
    TextEditor,
    Comentarios,
    flatPickr,
    Cleave,
  },
  data() {
    return {
      form: {
        nomeImovel: null,
        numero: null,
        complemento: null,
        cep: null,
        logradouto: null,
        bairro: null,
        cidade: null,
        service: null,
        closet: null,
        piscina: null,
        quartoEmp: null,
        churrasqueira: null,
        maquinas: null,
        projetoObra: null,
        andares: null,
        garagem: null,
        numeroUnidade: null,
        area: null,
        tipo: null,
        suites: null,
        dormitorios: null,
        banheiros: null,
        lavabo: null,
      },
      options: [
        { value: 's', text: 'Sim' },
        { value: 'n', text: 'Não' },
      ],
      status: [
        { value: 'rejeitado', text: 'Rejeitado' },
        { value: 'pendente', text: 'Pendente de Informação' },
      ],
      projetos: [
        { value: 'proj01', text: 'Nome do Projeto/Obra 01' },
        { value: 'proj02', text: 'Nome do Projeto/Obra 02' },
        { value: 'proj03', text: 'Nome do Projeto/Obra 03' },
        { value: 'proj04', text: 'Nome do Projeto/Obra 04' },
        { value: 'proj05', text: 'Nome do Projeto/Obra 05' },
      ],
      cleaveOptions: {
        cep: {
          delimiters: ['.', '-'],
          blocks: [2, 3, 3],
          uppercase: true,
          numericOnly: true,
        },
        money: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
          prefix: 'R$ ',
          signBeforePrefix: true,
        },
      },
    }
  },
  methods: {
    letras(e) {
      let charCode
      let retn
      try {
        if (window.event) {
          charCode = window.event.keyCode
        } else if (e) {
          charCode = e.which
        } else {
          retn = true
        }
        if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
          retn = true
        } else {
          retn = false
        }
      } catch (error) {
        alert(error.Description)
      }
      return retn
    },
    async buscaCep() {
      const cep = this.form.cep.replace('.', '').replace('-', '')
      try {
        await this.$http.get(`https://viacep.com.br/ws/${cep}/json/`).then(response => {
          // this.dataContact = {
          //   ...this.dataContact,
          //   logradouro: response.data.logradouro,
          //   city: response.data.localidade,
          //   district: response.data.bairro,
          // }
          this.form = {
            ...this.form,
            logradouro: response.data.logradouro,
            cidade: response.data.localidade,
            estado: response.data.uf,
            bairro: response.data.bairro,
          }
        })
      } catch (error) {
        this.msgError()
      }
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao editar imóvel',
        text: this.error,
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import './sass/variables.scss';
@import './sass/company';
body {
  background-color: #f8f8f8 !important;
}
</style>
